<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="/hazards">Hazard & Risk Templates</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button mat-raised-button
                *ngIf="dataSource.archived === 'false'"
                color="primary"
                appMarginRight
                [matMenuTriggerFor]="linkMenu"
                id="add"
                matTooltip="Create a Hazard/Risk template and add it to your list.">
          <mat-icon>add</mat-icon>
          Add
        </button>

        <mat-menu #linkMenu="matMenu">
          <button mat-menu-item (click)="onAdd()">
            Create New
          </button>
          <button mat-menu-item (click)="onSelectHazards()">
            Select from All {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }} Hazards
          </button>
      </mat-menu>

        <button mat-raised-button
                *ngIf="dataSource.archived === 'false'"
                class="mr-2"
                color=""
                routerLink="/hazards/importer">
          <mat-icon>cloud_upload</mat-icon> Import
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="dataSource.selectAllToggle()">
            <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
            {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>
          <button *ngIf="dataSource.archived === 'false'" mat-menu-item (click)="onExportSelected('pdf')">
            <mat-icon>cloud_download</mat-icon> Export {{ dataSource.selection.hasValue() ? '(' + dataSource.selection.selected.length + ') Selected' : 'All' }} to PDF
          </button>
          <button *ngIf="dataSource.archived === 'false'" mat-menu-item (click)="onExportSelected('csv')">
            <mat-icon>cloud_download</mat-icon> Export {{ dataSource.selection.hasValue() ? '(' + dataSource.selection.selected.length + ') Selected' : 'All' }} to CSV
          </button>
          <button *ngIf="dataSource.archived === 'false'" mat-menu-item (click)="onDeleteSelected()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>archive</mat-icon> Archive Selected
          </button>
          <button *ngIf="dataSource.archived === 'true'" mat-menu-item (click)="onRestoreSelected()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>restore</mat-icon> Restore Selected
          </button>
        </mat-menu>

        <button *ngIf="dataSource.archived === 'false'" mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect and/or export Hazard & Risk templates." style="min-width: 150px">
          <mat-icon>more_vert</mat-icon>
          Menu
        </button>

        <button *ngIf="dataSource.archived === 'true'" mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect Hazard & Risk templates." style="min-width: 120px">
          <mat-icon>more_vert</mat-icon>
          Menu
        </button>
      </span>

      <button type="button" mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" (click)="chartsUtils.onOpenMasterCharts('Hazards & Risks')" matTooltip="Click this button to open the charts.">
        <mat-icon>bar_chart</mat-icon> Charts
      </button>

      <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" (click)="onFilter()" matTooltip="Click this button to display filter options.">
        <mat-icon>filter_list</mat-icon> Filter
      </button>

      <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)" style="min-width: 325px">
        <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search"
          placeholder="Search" aria-label="Search" matTooltip="Filter the list of Hazard & Risk templates by name or status" />
        <button mat-raised-button
                color="secondary"
                type="submit"
                class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
                matTooltip="Run the current search against the list of Hazard & Risk templates">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>

    </div>
  </nav>

  <app-hazards-charts-container [baseStorageKey]="'hazards'"></app-hazards-charts-container>

  <div class="mat-elevation-z8 scroll-box">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef width="65px">
          <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null" [checked]="
              dataSource.selection.hasValue() && dataSource.isAllSelected()
            " [indeterminate]="
              dataSource.selection.hasValue() && !dataSource.isAllSelected()
            " matTooltip="Toggle selecting all Hazard & Risk templates on the page."></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.id) : null"
            [checked]="dataSource.selection.isSelected(row.id)" matTooltip="Toggle selecting this Hazard/Risk.">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by Hazard/Risk ID">#</th>
        <td mat-cell *matCellDef="let element" class="text-wrap">
          {{ element.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="site">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}
        </th>
        <td mat-cell *matCellDef="let element" class="text-wrap">
          <ng-container *ngIf="element.site">
            <ng-container *ngIf="element?.site?.parent_site">
              <small style="display: block;">
                <a [routerLink]="utils.prepareSiteRelatedLink(element?.site?.parent_site?.id, null, '/edit/details')">
                  {{ element?.site?.parent_site?.name || '' }}
                </a>
              </small>
            </ng-container>
            <mat-icon *ngIf="element?.site?.parent_site_id" style="font-size: 16px;">subdirectory_arrow_right</mat-icon>
            <a [routerLink]="utils.prepareSiteRelatedLink(element?.site?.id, element?.site?.parent_site?.id, '/edit/details')">
              {{ element?.site?.name || '' }}
            </a>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by Hazard/Risk">Hazard/Risk</th>
        <td mat-cell *matCellDef="let element" class="text-wrap">
          <b>{{ element.name }}</b> <br />
          <small>{{ element.description }}</small>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by Status">Status</th>
        <td mat-cell *matCellDef="let element">{{ element.status }}</td>
      </ng-container>

      <ng-container matColumnDef="ira">
        <th mat-header-cell *matHeaderCellDef>
          <span matTooltip="Initial Risk Assessment">IRA</span>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-chip [class]="'mat-chip ' + utils.getSiteRiskStatusChipColor(utils.getRiskAssessmentText(element.ira_likelihood, element.ira_severity))">
            {{ utils.getRiskAssessmentText(element.ira_likelihood, element.ira_severity) }}
          </mat-chip>
        </td>
      </ng-container>

      <ng-container matColumnDef="rra">
        <th mat-header-cell *matHeaderCellDef>
          <span matTooltip="Residual Risk Assessment">RRA</span>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-chip [class]="'mat-chip ' + utils.getSiteRiskStatusChipColor(utils.getRiskAssessmentText(element.rra_likelihood, element.rra_severity))">
            {{ utils.getRiskAssessmentText(element.rra_likelihood, element.rra_severity) }}
          </mat-chip>
        </td>
      </ng-container>

      <ng-container matColumnDef="controls">
        <th mat-header-cell *matHeaderCellDef>Controls</th>
        <td mat-cell *matCellDef="let element">
          {{ element.controls.length }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the Hazard/Risk was created.">Created At</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.date_created * 1000) | timezoneConvertMedium }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the Hazard/Risk was created.">Created At (UTC)</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.date_created * 1000) | timezoneConvertUTCMedium }}
        </td>
      </ng-container>


      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="custom-menu"></th>

        <td mat-cell *matCellDef="let element">
          <a *ngIf="dataSource.archived == 'false'" type="button" mat-button routerLink="/hazards/{{ element.id }}/view" matTooltip="View the Hazard/Risk information." matTooltipPosition="above">
            View
          </a>

          <button *ngIf="dataSource.archived == 'false'" type="button" mat-button (click)="onEdit(element)" matTooltip="Edit the Hazard/Risk information." matTooltipPosition="above">
            Edit
          </button>

          <button *ngIf="dataSource.archived === 'false'" type="button" mat-button (click)="onCreateFrom(element)" matTooltip="Clone this Hazard/Risk information." matTooltipPosition="above">
            Clone
          </button>

          <button *ngIf="dataSource.archived == 'false'" type="button" mat-button (click)="onRemove(element.id)" matTooltip="Remove (Archive) the Hazard/Risk." matTooltipPosition="above">
            Archive
          </button>

          <button *ngIf="dataSource.archived == 'true'" type="button" mat-button (click)="onRestore(element.id)" matTooltip="Restore this Hazard/Risk." matTooltipPosition="above">
            Restore
          </button>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <input type="file" mat-raised-button (change)="onImport($event)" style="display: none;" #fileInput />

    <mat-paginator [length]="dataSource?.total | async" [pageSize]="dataSource.limit"
      [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>
