import {Component, Inject, OnInit} from '@angular/core';
import {RiskLevel} from "../../shared/risk-matrix/risk-matrix.component";
import {AppService} from "../../app.service";
import {UtilsService} from "../../shared/utils.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-hazards-risks-edit',
  templateUrl: './hazards-risks-edit.component.html',
  styleUrls: ['./hazards-risks-edit.component.scss']
})
export class HazardsRisksEditComponent implements OnInit {

  title: string = 'Risk Matrix';

  risk_type: string = '';

  risk_level: RiskLevel;

  // Used to store the chart data.
  chartData: any[][] = [
    [0,0,0,0,0],
    [0,0,0,0,0],
    [0,0,0,0,0],
    [0,0,0,0,0],
    [0,0,0,0,0]
  ];

  constructor(
    public app: AppService,
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<HazardsRisksEditComponent>,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    // Set default values if not provided.
    this.risk_level = this.data['selected'] || {
      likelihood: 2,
      severity: 2
    } as RiskLevel;
    this.risk_type = this.data['type'] || '';
  }

  /**
   * Sets the risk level for assessment.
   *
   * @param {RiskLevel} event - The event object representing the RiskLevel instance.
   * @return {void}
   */
  getRiskLevel(event: RiskLevel): void {
    // Store the risk level.
    this.risk_level = event;
  }

  /**
   * Saves the risk level and closes the dialog.
   *
   * @return {void}
   */
  saveRiskLevel() {
    this.utils.showToast(`The ${this.risk_type} Risk Level was saved.`);
    this.dialogRef.close(this.risk_level);
  }

}
