import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {ApiService} from "../../shared/api.service";
import {ActivatedRoute} from "@angular/router";
import {ApiRequestService} from "../../shared/api-request.service";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {ApiDataSource} from "../../utils/api-data-source";
import {HazardModel} from "../../models/hazard.model";
import {HazardsEditComponent} from "../../hazards/hazards-edit/hazards-edit.component";
import {MatDialog} from "@angular/material/dialog";
import {SitesHazardsViewComponent} from "../../sites/sites-hazards-view/sites-hazards-view.component";
import {HazardsSelectorComponent} from "../../hazards/hazards-selector/hazards-selector.component";
import {SiteHazardsSelectorComponent} from "../../shared/site-hazards-selector/site-hazards-selector.component";
import {tap} from "rxjs/operators";

@Component({
  selector: 'app-incidents-hazards',
  templateUrl: './incidents-hazards.component.html',
  styleUrls: ['./incidents-hazards.component.scss']
})
export class IncidentsHazardsComponent implements OnInit, AfterViewInit {

  @Input() private incidentId: number;
  @Input() private siteId: number;

  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'id',
    'name',
    'status',
    'ira',
    'rra',
    'controls',
    'date_created',
    // 'date_created_UTC',
    'reviewer_date',
    'actions'
  ];

  // the paginator and sorter
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  dataSource: IncidentsHazardsDataSource = new IncidentsHazardsDataSource(this.app, this.api);

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private dialog: MatDialog,
    private oldApi: ApiService,
    public route: ActivatedRoute,
    private api: ApiRequestService
  ) { }

  ngOnInit() {
    this.dataSource.incident_id = this.incidentId;
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onView(hazard: HazardModel) {
    this.utils.showComponentDialog(
      SitesHazardsViewComponent,
      hazard.id,
      { width: '900px' },
      (success) => {
        if (!success) { return; }
        this.dataSource.getData();
      }
    );
  }

  onAdd() {
    const hazard = new HazardModel();
    hazard.site_id = this.siteId;
    hazard.incident_link_id = this.incidentId;
    this.onEdit(hazard);
  }

  onEdit(hazard: HazardModel) {
    this.dialog
      .open(HazardsEditComponent, {
        data: {...hazard}
      })
      .afterClosed()
      .subscribe((success) => {
        // if (!success) { return; } // Removed to update list on close
        this.dataSource.getData(true);
      });
  }

  onSelectHazards() {
    this.utils.showComponentDialog(
      SiteHazardsSelectorComponent,
      {
        selected: [],
        multiple: true,
        selectedAccountId: this.app.account.id,
        site_id: this.siteId,
        incident_link_id: this.dataSource.incident_id
      },
      {
        width: '1024px'
      },
      (results: number[]) => {
        if (!results) { return; }
        this.linkHazards(results);
      }
    );
  }

  private linkHazards(hazard_ids: number[]) {
    // Terminate early if no incident id is present.
    if ( !this.incidentId ) {
      return;
    }

    // Check if there are any hazards to link.
    if ( hazard_ids.length === 0 ) {
      this.utils.showModal('Select Hazards/Risks', 'You need to select some hazards/risks before they can be linked.');
      return;
    }

    // Make the API request to link the hazards.
    this.api.makeRequest('put', `v2/incidents/${this.incidentId}/hazards`, {}, {
      hazard_ids: hazard_ids.join(',')
    })
      .then((response) => {
        // Refresh the list.
        this.dataSource.getData(true);
        // Show toast message
        this.utils.showToast(`${hazard_ids.length} Hazards/Risks were linked to the Incident.`);
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onCopy() {
    // show the hazard selector
    this.utils.showComponentDialog(
      HazardsSelectorComponent,
      {
        multiple: true
      },
      {},
      (results) => {
        if (typeof results !== 'undefined') {
          this.copyHazardsToSite(results);
        }
      }
    );
  }

  copyHazardsToSite(selected_hazard_ids) {
    this.oldApi.laravelApiRequest(
      'put',
      'sites/' + this.siteId + '/hazards',
      {
        selected_hazard_ids: selected_hazard_ids,
        incident_link_id: this.incidentId
      },
      {},
      (response) => {
        this.dataSource.getData(true);
        this.utils.showToast('Hazards/Risks were copied to the ' + this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site') + ' and linked to the Incident.');
      },
      (error) => {
        this.utils.showModal('Error', error.message);
      }
    );
  }


  /**
   * Unlinks a hazard from incident.
   *
   * @param {number} hazard_id - The ID of the hazard to unlink.
   *
   * @return {void}
   */
  onUnlink(hazard_id: number) {
    this.unlinkHazards([hazard_id]);
  }

  /**
   * Unlinks the selected hazards from the incident.
   *
   * @returns {void}
   */
  onUnlinkSelected() {
    this.unlinkHazards(this.dataSource.selection.selected);
  }

  /**
   * Remove hazards from the list.
   *
   * @param {number[]} hazard_ids - An array of hazard IDs to be removed.
   * @private
   * @returns {void}
   */
  private unlinkHazards(hazard_ids: number[]){
    // Terminate early if no incident id is present.
    if ( !this.incidentId ) {
      return;
    }

    // Check if there are any hazards to remove.
    if ( hazard_ids.length === 0 ) {
      this.utils.showModal('Select Hazards/Risks', 'You need to select some hazards/risks before they can be unlinked.');
      return;
    }

    // Get Confirmation
    this.utils.showModal('Unlink Hazards/Risks', `Are you sure you want to unlink ${hazard_ids.length} hazards/risks?`, () => {
      // Make the API request to remove the users.
      this.api.makeRequest('delete', `v2/incidents/${this.incidentId}/hazards`, {}, {
        hazard_ids: hazard_ids.join(',')
      })
        .then((response) => {
          // Refresh the list.
          this.dataSource.getData(true);
          // Show toast message
          this.utils.showToast(`${hazard_ids.length} Hazards/Risks were unlinked.`);
          // Clear selected items.
          this.dataSource.selection.deselect(...hazard_ids);
        })
        .catch((errorResponse) => {
          this.utils.handleAPIErrors(errorResponse);
        });
    });

  }

  /**
   * Count the hazard controls.
   * @param controls
   */
  getHazardControlsCount(controls: any) {
    // Parse the controls.
    const parsedControls = JSON.parse(controls);
    // Check if we have a value for length.
    if ( parsedControls.length ) {
      return parsedControls.length;
    }
    return '';
  }

}

export class IncidentsHazardsDataSource extends ApiDataSource {
  order_by = 'name';
  order = 'asc';

  incident_id: number = 0;

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/incidents/${this.incident_id}/hazards`, resetOffset, {
      eager_load: true
    });
  }
}
