import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { HazardsSelectorComponent } from 'src/app/hazards/hazards-selector/hazards-selector.component';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { CustomDataSource } from 'src/app/utils/custom-data-source';
import { HazardsEditComponent } from 'src/app/hazards/hazards-edit/hazards-edit.component';
import { HazardModel } from 'src/app/models/hazard.model';
import { SitesHazardsViewComponent } from '../sites-hazards-view/sites-hazards-view.component';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import {SitesHazardsFilterComponent} from "../sites-hazards-filter/sites-hazards-filter.component";

@Component({
  selector: 'app-sites-hazards',
  templateUrl: './sites-hazards.component.html',
  styleUrls: ['./sites-hazards.component.scss']
})
export class SitesHazardsComponent implements OnInit, AfterViewInit {

  parent_id: number;
  child_id: number;
  site_id: number;

  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'id',
    'site',
    'name',
    'status',
    'ira',
    'rra',
    'controls',
    'date_created',
    // 'date_created_UTC',
    // 'created_by',
    'reviewer_date',
    'actions'
  ];
  dataSource: SiteHazardsDataSource = new SiteHazardsDataSource(this.app, this.oldApi, true);

  // the paginator and sorter
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private oldApi: ApiService,
    public route: ActivatedRoute,
    private api: ApiRequestService
  ) { }

  ngOnInit() {
    // Get the site id from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Store the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;
      // init the data source
      this.dataSource.site_id = this.site_id;
      this.dataSource.site_ids.push(this.site_id);
      // get the data
      this.dataSource.getData(true);
    }
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_order = this.sort.direction;

      // sorting for utc time by parsing original time
      if (this.sort.active == "date_created_UTC") {
        this.dataSource.sort_by = "date_created";
      } else {
        this.dataSource.sort_by = this.sort.active;
      }

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onView(hazard: HazardModel) {
    this.utils.showComponentDialog(
      SitesHazardsViewComponent,
      hazard.id,
      { width: '900px' },
      (success) => {
        if (!success) { return; }
        this.dataSource.getData(true);
      }
    );
  }

  onAdd() {
    const hazard = new HazardModel();
    hazard.site_id = this.site_id;
    this.onEdit(hazard);
  }

  onEdit(hazard: HazardModel) {
    // open the hazard edit dialog for editing
    this.utils.showComponentDialog(
      HazardsEditComponent,
      { ...hazard },
      {},
      (success) => {
        // if (!success) { return; } // Removed to update list on close
        this.dataSource.getData(true);
      }
    );
  }

  /**
   * @param id the id of the hazard.
   * Remove a single hazard.
   */
  onRemove(id: number) {
    this.utils.showModal(
      'Archive Hazard/Risk',
      'Are you sure you want to archive this Hazard/Risk?',
      () => {
        this.onRemoveHazards([id], (response) => {
          this.dataSource.selection.deselect(id);
        });
      }
    );
  }

  onRestore(id: number) {
    this.utils.showModal(
      'Restore Hazard/Risk',
      'Are you sure you want to restore this Hazard/Risk?',
      () => {
        this.onRestoreHazards([id], (response) => {
          this.dataSource.selection.deselect(id);
        });
      }
    );
  }

  /**
   * Remove selected hazards from the specified site
   */
  onDeleteSelected() {
    this.utils.showModal(
      'Archive Selected Hazards & Risks',
      'Are you sure you want to archive the selected Hazards & Risks?',
      () => {
        this.onRemoveHazards(this.dataSource.selection.selected, (response) => {
          this.dataSource.selection.clear();
        });
      }
    );
  }

  onRestoreSelected() {
    this.utils.showModal(
      'Restore Selected Hazards & Risks',
      'Are you sure you want to restore the selected Hazards & Risks?',
      () => {
        this.onRestoreHazards(this.dataSource.selection.selected, (response) => {
          this.dataSource.selection.clear();
        });
      }
    );
  }

  /**
   * Open a dialog to select hazards.
   */
  onSelectHazards() {
    // show the hazard selector
    this.utils.showComponentDialog(
      HazardsSelectorComponent,
      {
        multiple: true
      },
      {},
      (results) => {
        if (typeof results !== 'undefined') {
          this.copyHazardsToSite(results);
        }
      }
    );
  }

  copyHazardsToSite(selected_hazard_ids) {
    this.oldApi.laravelApiRequest(
      'put',
      'sites/' + this.site_id + '/hazards',
      {
        selected_hazard_ids: selected_hazard_ids
      },
      {},
      (response) => {
        this.dataSource.getData(true);
        this.utils.showToast('Hazards/Risks were copied to the ' + this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site') + '.');
      },
      (error) => {
        this.utils.showModal('Error', error.message);
      }
    );
  }

  onRemoveHazards(hazard_ids: number[], callback?: any) {
    if ( this.dataSource.site_ids.length ) {
      // Remove hazards
      this.oldApi.laravelApiRequest(
        'delete',
        'hazards/' + hazard_ids.join(',') + '?site_id=' + this.dataSource.site_ids.join(','),
        {},
        {},
        (response) => {
          // reload the data and reset the offset
          this.dataSource.getData(true);

          this.utils.showToast('The selected Hazards/Risks were archived.');

          if (typeof callback === 'function') {
            callback(response);
          }
        },
        (error) => {
          this.utils.showModal('Error', error.message);
        }
      );
    }
  }

  onRestoreHazards(hazard_ids: number[], callback?: any) {
    if ( this.dataSource.site_ids.length > 0 ) {
      // Restore hazards.
      this.oldApi.laravelApiRequest(
        'put',
        'hazards/' + hazard_ids.join(',') + '/restore?site_id=' + this.dataSource.site_ids.join(','),
        {},
        {},
        (response) => {
          // reload the data and reset the offset
          this.dataSource.getData(true);
          this.utils.showToast('The selected Hazards/Risks were restored.');

          if (typeof callback === 'function') {
            callback(response);
          }
        },
        (error) => {
          this.utils.showModal('Error', error.message);
        }
      );
    }
  }

  /**
   * Exports the list of hazards into the specified format and sends a download request to the browser.
   * @param type csv, xls, xlsx, pdf.
   * Last updated by Kobus Beets on 13/1/2021.
   */
  onExportSelected(type: string = 'xlsx') {
    this.api.makeDownloadRequest(`v1/hazards/export/${type}` + (this.dataSource.selection.selected.length ? '/' + this.dataSource.selection.selected.join(',') : ''), {}, {
      site_ids: this.site_id,
      order_by: this.dataSource.sort_by,
      order: this.dataSource.sort_order
    })
      .then((response) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(response);
          return;
        }

        // Get the current date object
        const date = new Date();

        // Create object url to handle file downloads
        const data = window.URL.createObjectURL(response);

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = data;
        downloadLink.download = `hazards-risks-${date.getFullYear()}${(date.getMonth() + 1)}${date.getDate()}.${type}`;
        // Initiate the download
        downloadLink.click();

        // For Firefox it is necessary to delay revoking the ObjectURL
        setTimeout(function () {
          window.URL.revokeObjectURL(data);
        }, 300); // Minimum 300 miliseconds
      });
  }

  onCreateFrom(hazard: HazardModel) {
    const newHazard: HazardModel = new HazardModel();
    newHazard.createFrom(hazard);
    newHazard.site_id = this.site_id;
    this.onEdit(newHazard);
  }

  onFilter() {
    this.utils.showComponentDialog(
      SitesHazardsFilterComponent,
      {
        selected_site_ids: this.dataSource.site_ids,
        archived: this.dataSource.archived,
        parent_site_id: this.parent_id
      },
      {
        width: '768px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.dataSource.site_ids = results.site_ids;
          this.dataSource.archived = results.archived || this.dataSource.archived;

          if ( this.dataSource.site_ids.length === 0 ) {
            this.dataSource.site_ids.push(this.site_id);
          }

          this.dataSource.getData();
        }
      }
    );
  }

  /**
   * Count the hazard controls.
   * @param controls
   */
  getHazardControlsCount(controls: any) {
    // Parse the controls.
    const parsedControls = JSON.parse(controls);
    // Check if we have a value for length.
    if ( parsedControls.length ) {
      return parsedControls.length;
    }
    return '';
  }
}

export class SiteHazardsDataSource extends CustomDataSource {

  sort_by = 'name';
  sort_order = 'asc';

  site_id: number = 0;

  site_ids = [];

  archived = 'false';

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI(
      'sites/' + this.site_id + '/hazards',
      resetOffset,
      () => { },
      {
        eager_load: true,
        site_ids: this.site_ids.join(','),
        archived: this.archived
      }
    );
  }
}
