<div mat-dialog-title>
  <h5>Hazard/Risk {{ !hazard.site_id ? 'Template' : '' }}</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)" appPadding>
    <mat-form-field>
      <input
        matInput
        placeholder="Hazard/Risk"
        [(ngModel)]="hazard.name"
        name="name"
        type="text"
        #name
        required />
      <mat-error *ngIf="!name.valid">Please enter the Hazard/Risk name. </mat-error>
    </mat-form-field>

    <mat-form-field>
      <textarea
        rows="7"
        matInput
        placeholder="Description"
        [(ngModel)]="hazard.description"
        name="description"
        #description
        required>
      </textarea>
      <mat-error *ngIf="!description.valid">Please enter a description for this Hazard/Risk.</mat-error>
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-select
        placeholder="Status"
        [(ngModel)]="hazard.status"
        name="status"
        required
        id="status">
        <mat-option value="Unverified">Unverified</mat-option>
        <mat-option value="Verified">Verified</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="hazard?.id && hazard?.site_id" class="full-width mb-3">
      <mat-label>Select the upcoming review date.</mat-label>
      <input matInput [ngxMatDatetimePicker]="reviewedAtDateTimePickerRef" readonly name="reviewedAtDateTime" [(ngModel)]="reviewedAtDateTime">
      <mat-datepicker-toggle matSuffix [for]="reviewedAtDateTimePickerRef"></mat-datepicker-toggle>
      <button type="button" mat-button matSuffix mat-icon-button *ngIf="reviewedAtDateTime" (click)="reviewedAtDateTime = null" matTooltip="Clear the date and time."><mat-icon>close</mat-icon></button>
      <ngx-mat-datetime-picker #reviewedAtDateTimePickerRef [showSeconds]="false" [touchUi]="false" [enableMeridian]="false"></ngx-mat-datetime-picker>
      <mat-hint>
        Use the calendar icon to select the date and time.<br>
      </mat-hint>
    </mat-form-field>

    <h5 class="card-title">Initial Risk Assessment</h5>
    <button
      type="button"
      mat-raised-button
      color="secondary"
      appMarginLeft
      (click)="onViewMatrix('Initial')"
      matTooltip="View/Update Initial Risk Matrix">
      <mat-icon>assessment</mat-icon>
      Initial Risk Matrix
    </button>

    <mat-form-field class="full-width" appMarginTop>
      <mat-select
        placeholder="Likelihood"
        [(ngModel)]="hazard.ira_likelihood"
        name="ira_likelihood"
        required
        id="ira_likelihood">
        <mat-option
          *ngFor="let likelihood of utils.getRiskAssessmentLikelihoodList(); let i = index"
          [value]="i + 1">
          {{ likelihood }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-select
        [placeholder]="utils.getLangTerm('hazards.form.severity', 'Severity')"
        [(ngModel)]="hazard.ira_severity"
        name="ira_severity"
        id="ira_severity"
        required>
        <mat-option
          *ngFor="let severity of utils.getRiskAssessmentSeverityList(); let i = index"
          [value]="i + 1">
          {{ severity }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div
      class="alert alert-{{ getRiskAssessmentColorChange(hazard.ira_likelihood, hazard.ira_severity) }}"
      role="alert">{{ utils.getRiskAssessmentText(hazard.ira_likelihood, hazard.ira_severity) }}
    </div>

    <h5 class="card-title">Hazard/Risk Controls</h5>
    <button
      type="button"
      mat-raised-button
      color="secondary"
      appMarginLeft
      (click)="onAddControl()"
      matTooltip="Add Hazard/Risk Control">
      <mat-icon>add</mat-icon>
      Add Control
    </button>

    <div appMarginTop *ngFor="let control of hazard.controls; let i = index">
      <mat-form-field class="full-width">
        <input
          matInput
          placeholder="Control {{ i + 1 }}"
          [(ngModel)]="hazard.controls[i].control"
          [ngModelOptions]="{ standalone: true }"
          type="text"
          required />
        <mat-error *ngIf="!hazard.controls[i].control">Please enter a control.</mat-error>
      </mat-form-field>

      <div class="row">
        <div class="col-10">
          <mat-form-field class="full-width">
            <mat-select
              placeholder="Level of Control"
              [(ngModel)]="hazard.controls[i].loc"
              [ngModelOptions]="{ standalone: true }"
              required>
              <mat-option
                *ngFor="let loc of loc_list; let i = index"
                [value]="i + 1">
                {{ loc }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="!hazard.controls[i].loc">Please select a level of control.</mat-error>
          </mat-form-field>
        </div>

        <div class="col-2">
          <button
            type="button"
            mat-icon-button
            (click)="onRemoveControl(i)"
            *ngIf="hazard.controls.length > 1"
            matTooltip="Delete this Hazard/Risk Control">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <h5 class="card-title">Residual Risk Assessment</h5>
    <button
      type="button"
      mat-raised-button
      color="secondary"
      appMarginLeft
      (click)="onViewMatrix('Residual')"
      matTooltip="View/Update Residual Risk Matrix">
      <mat-icon>assessment</mat-icon>
      Residual Risk Matrix
    </button>

    <mat-form-field class="full-width" appMarginTop>
      <mat-select
        placeholder="Likelihood"
        [(ngModel)]="hazard.rra_likelihood"
        name="rra_likelihood"
        id="rra_likelihood"
        required>
        <mat-option
          *ngFor="let likelihood of utils.getRiskAssessmentLikelihoodList(); let i = index"
          [value]="i + 1">
          {{ likelihood }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-select
        [placeholder]="utils.getLangTerm('hazards.form.severity', 'Severity')"
        [(ngModel)]="hazard.rra_severity"
        name="rra_severity"
        id="rra_severity"
        required>
        <mat-option
          *ngFor="let severity of utils.getRiskAssessmentSeverityList(); let i = index"
          [value]="i + 1">
          {{ severity }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div
      class="alert alert-{{ getRiskAssessmentColorChange(hazard.rra_likelihood, hazard.rra_severity) }}"
      role="alert">
      {{ utils.getRiskAssessmentText(hazard.rra_likelihood, hazard.rra_severity) }}
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-raised-button
    style="max-width: 100px;"
    [disabled]="!f.valid || inProgress || !hasValidControls()"
    color="primary"
    class="save-button"
    (click)="onSubmit(f)"
    id="save">
    <mat-icon>save</mat-icon>
    Save
  </button>

  <button
    mat-raised-button
    color="secondary"
    (click)="onSelectSites()"
    [matBadge]="'' + (hazard.copy_to_all_sites ? 'All' : hazard.selected_site_ids?.length)"
    [matBadgeHidden]="!hazard?.selected_site_ids || hazard.copy_to_all_sites || hazard.selected_site_ids?.length <= 0"
    *ngIf="hazard?.id && !hazard?.site_id"
    matTooltip="Select the {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} this Hazard/Risk will be associated with.">
    <mat-icon>file_copy</mat-icon>
    Copy to {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
  </button>

  <mat-checkbox
    [(ngModel)]="hazard.copy_to_all_sites"
    name="copy_to_all_sites"
    appMarginRight
    appMarginTop
    *ngIf="hazard?.id && !hazard?.site_id"
    matTooltip="Associate this Hazard/Risk with all {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }}.">
    Select all {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }}
  </mat-checkbox>

  <app-files-selector
    (selected)="newFiles = $event"
    [selectedFilesCount]="newFiles?.length"
    appMarginRight>
  </app-files-selector>

  <button
    mat-raised-button
    (click)="onRemoveFiles()"
    color="secondary"
    [matBadge]="'' + (hazard.files?.length)"
    [disabled]="!hazard.files?.length"
    appMarginRight
    type="button">
    <mat-icon>open_in_browser</mat-icon>
    Manage Files
  </button>

  <button
    type="button"
    mat-raised-button
    color="secondary"
    (click)="onSelectReviewer()"
    [matBadge]="'' + (hazard.reviewer_id ? 1 : 0)"
    *ngIf="hazard?.id && hazard?.site_id"
    matTooltip="You can optionally select a reviewer.">
    <mat-icon>rate_review</mat-icon>
    {{ !hazard.reviewer_id ? 'Select' : 'Change' }} Reviewer
  </button>

  <span class="mr-auto"></span>

  <button
    mat-raised-button
    color="secondary"
    [disabled]="!hazard?.id"
    class="float-right"
    (click)="onRemove()"
    matTooltip="Archive this Hazard">
    <mat-icon>archive</mat-icon>
    Archive
  </button>
</mat-dialog-actions>
